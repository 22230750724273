import Layout from 'features/shared/Layout';

import { ErrorScreen } from '@frontendmentorio/design-system-v2';

export default function NotFound() {
  return (
    <Layout isIndexed={false}>
      <ErrorScreen
        code="404"
        heading="Sorry, we can’t find the page you’re looking for"
      />
    </Layout>
  );
}
